<template>
  <div class="row">
    <div class="col-12">
      <q-btn-group outline class="float-right">
        <c-update-btn 
          name="updateBtn"
          :data="updateBtnData"
          :btnEditable="btnEditable"
          :flagCondition="flagCondition"
          @back="back"
        />
        <!-- 삭제 -->
        <c-btn 
          v-show="editable&&!deleteDisabled&&isOld" 
          label="LBLREMOVE" 
          icon="remove" 
          @btnClicked="removePlan" />
        <!-- 평가완료 -->
        <c-btn 
          v-show="editable&&!disabled&&!btnEditable" 
          :isSubmit="isComplete"
          :url="completeUrl"
          :param="saveProcess"
          mappingType="PUT"
          label="LBL0001162" 
          icon="check"
          @beforeAction="completeProcess"
          @btnCallback="completeProcessCallback" />
      </q-btn-group>
    </div>
    <div class="col-12">
      <!-- 도면 -->
      <c-tab
        type="tabcard"
        align="left"
        title="LBL0001001"
        :tabItems.sync="process.draws"
        :inlineLabel="true"
        v-model="tab"
      >
        <template v-slot:default="tab">
          <component
            :is="tab.component"
            :key="tab.componentKey"
            :draw.sync="tab.draw"
            :process.sync="process"
            :param="param"
            :disabled="disabled"
            :updateBtnData="updateBtnData"
            :planUpdateBtnData="planUpdateBtnData"
            @research="research"
          />
        </template>
      </c-tab>
    </div>
  </div>
</template>

<script>
import { uid } from 'quasar'
import transactionConfig from '@/js/transactionConfig';
export default {
  name: 'kpsr-action-detail',
  props: {
    process: {
      type: Object,
      default: () => ({
        ramRiskAssessmentPlanId: '',  // 평가 일련번호
        processCd: '',  // 단위공정 코드
        processName: '',  // 단위공정명
        ramProcessAssessStepCd: '',  // 평가진행상태
        processExplainFeature: '',  // 공정 특징 설명
        assessDeptCd: '',  // 평가 부서 코드
        assessUserId: '',  // 평가 담당자
        assessUserName: '',  // 평가 담당자
        draws: [],
        reCheckedCnt: 0,
      }),
    },
    param: {
      type: Object,
      default: () => ({
        ramRiskAssessmentPlanId: '',
        ramStepCd: '',
        ramTechniqueCd: '',
      }),
    },
    planUpdateBtnData: {
      title: 'LBLPLAN', // 계획
      flag: false,
      research: '',
      planResearch: '',
    },
  },
  data() {
    return {
      editable: true,
      tab: '',
      deleteUrl: '',
      completeUrl: '',
      isComplete: false,
      saveProcess: {
        ramRiskAssessmentPlanId: '',  // 평가 일련번호
        processCd: '',  // 단위공정 코드
        processName: '',  // 단위공정명
        ramProcessAssessStepCd: '',  // 평가진행상태
        processExplainFeature: '',  // 공정 특징 설명
        assessDeptCd: '',  // 평가 부서 코드
        assessUserId: '',  // 평가 담당자
        draws: [],
      },
      updateBtnData: {
        title: 'LBL0001164', // 평가
        flag: false,
        research: '',
      },
    };
  },
  computed: {
    disabled() {
      return (!(this.param.ramStepCd === 'RRS0000005' || this.param.ramStepCd == 'RRS0000010') || this.process.ramProcessAssessStepCd === 'RPA0000005')
        && !this.updateBtnData.flag
    },
    btnEditable() {
      return this.editable && this.param.ramStepCd === 'RRS0000010' && this.process.ramProcessAssessStepCd === 'RPA0000005'
    },
    flagCondition() {
      return this.param.ramStepCd === 'RRS0000010'
    },
    isOld() {
      return Boolean(this.param.ramRiskAssessmentPlanId)
    },
    deleteDisabled() {
      return this.param.ramStepCd === 'RRS0000015'
    },
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      // role setting
      this.editable = this.$route.meta.editable;
      // url setting
      this.deleteUrl = transactionConfig.ram.assessPlan.delete.url
      this.completeUrl = transactionConfig.ram.assessProcess.complete.url;
      // code setting
      // list setting
      this.setDraw();
    },
    setDraw() {
      if (this.process && this.process.draws) {
        this.tab = this.process.draws[0].psiDiagramId
      }
    },
    completeProcess() {
      // 도면 별 노드에 시나리오가 하나 이상 등록되었는지?
      // 화면에서 추가는 하고 저장을 하지 않았을 시에 해당 데이터도 일괄 저장 처리
      // 저장 처리 후 callback에서 process 목록 재조회
      let isProgress = true;
      this.$_.forEach(this.process.draws, draw => {
        this.$_.forEach(draw.nodes, node => {
          if (!node.scenarios || node.scenarios.length === 0) {
            isProgress = false
            window.getApp.$emit('ALERT', {
              title: 'LBLGUIDE', // 안내
              // 도면[' + draw.diagramTitle + '] / 노드 [' + node.nodeTitle + ']에 등록된 시나리오가 없습니다.
              message: this.$comm.getLangMessage('MSG0000396', {s1: draw.diagramTitle, s2: node.nodeTitle}),
              type: 'warning', // success / info / warning / error
            });
            return false;
          }
        });
        if (!isProgress) {
          return false;
        }
      })

      if (isProgress) {
        window.getApp.$emit('CONFIRM', {
          title: 'LBLCONFIRM',
          message: 'MSGCOMPLETE', // 완료하시겠습니까?,
          // TODO : 필요시 추가하세요.
          type: 'info', // success / info / warning / error
          // 확인 callback 함수
          confirmCallback: () => {
            this.process.chgUserId = this.$store.getters.user.userId
            this.process.ramProcessAssessStepCd = 'RPA0000005';

            this.saveProcess = this.$_.cloneDeep(this.process);
            this.$_.forEach(this.saveProcess.draws, draw => {
              delete draw.draw

              this.$_.forEach(draw.nodes, node => {
                this.$_.forEach(node.scenarios, scenario => {
                  if (scenario.editFlag === 'C') this.$set(scenario, 'editFlag', null)
                })
              })
            })
            console.log('## saveProcess: ', this.saveProcess)

            this.isComplete = !this.isComplete
          },
          // 취소 callback 함수
          cancelCallback: () => {
          },
        });
      }
    },
    completeProcessCallback(_result) {
      window.getApp.$emit('APP_REQUEST_SUCCESS');
      this.$emit('assessComplete', _result.data)
      
      this.planUpdateBtnData.planResearch = uid();
    },
    removePlan() {
      window.getApp.$emit('CONFIRM', {
        title: 'LBLCONFIRM',
        message: 'MSGREMOVE', // 삭제하시겠습니까?,
        // TODO : 필요시 추가하세요.
        type: 'info', // success / info / warning / error
        // 확인 callback 함수
        confirmCallback: () => {
          this.$http.url = this.$format(this.deleteUrl, this.param.ramRiskAssessmentPlanId);
          this.$http.type = 'DELETE';
          this.$http.request(() => {
            window.getApp.$emit('APP_REQUEST_SUCCESS');
            this.$emit('closePopup')
          },);
        },
        // 취소 callback 함수
        cancelCallback: () => {
        },
      });
    },
    research() {
      this.$emit('research', 'scenario')
    },
    /**
     * 재조회
     * 목적 : 돌아가기전 데이터를 저장 했을 수도 있지만 
     *        입력만 하고 돌아가는 경우를 대비하기 위함
     */
    back() {
      this.updateBtnData.research = uid();
    },
  }
};
</script>